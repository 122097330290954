




import { Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Component from 'vue-class-component';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class DiLiteHotelTransitionPage extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(DiLiteAllChannelsServiceS) diLiteAllChannelsService!: DiLiteAllChannelsService;

    @Prop({
        required: true,
        type: Number,
    })
    private hotelId!: number;

    @Watch('hotelId', { immediate: true })
    async initCurrentHotel(hotelId: number): Promise<void> {
        if (!this.userService.storeState.user) {
            return;
        }

        const isChainOrClusterUser = (this.userService.isChainUser || this.userService.isClusterUser);

        if (hotelId && this.$route.name && isChainOrClusterUser) {
            await this.userService.switchCurrentHotel(hotelId);
        }

        if (!isChainOrClusterUser) {
            this.userService.currentUserHotelInited = true;
        }

        const { path } = this.$route;
        const isChainOrClusterRoute = path.includes('hotels') || path.includes('chain') || path.includes('cluster');

        if (!isChainOrClusterRoute && isChainOrClusterUser) {
            this.userService.storeState.user = {
                ...this.userService.storeState.user,
                viewAs: USER_LEVELS.HOTEL,
            };
        }
    }
}
